import axios from "axios";
import { API_URL } from "../config/api";
import { notification } from "antd";
import { CreateAlertRequestType } from "./types";
import { AlertType } from "../providers/types";

export const getAlerts = async (token: string) => {
  let response = null;
  try {
    response = await axios({
      method: "get",
      url: `${API_URL}/alerts`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to fetch alerts",
    });

    console.error(error);
  }

  return response?.data;
  //   return [
  //     {
  //       id: "998496218965934082",
  //       appId: "902436143612985345",
  //       isActive: true,
  //       text: "hahaha",
  //       checkType: 1,
  //       createdAt: "2024-08-27T19:34:23.255Z",
  //       updatedAt: "2024-09-07T20:39:29.753Z",
  //       lastTriggered: null,
  //       channels: [
  //         {
  //           id: "998496219061583874",
  //           channelType: 1,
  //           params: {
  //             emails: ["t3ixxx@gmail.com"],
  //           },
  //         },
  //       ],
  //     },
  //   ];
};

export const createAlert = async (
  token: string,
  data: CreateAlertRequestType,
) => {
  let response = null;
  try {
    response = await axios({
      method: "post",
      url: `${API_URL}/alerts`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to fetch alerts",
    });

    console.error(error);
  }

  return response?.data;
};

export const editAlert = async (token: string, data: AlertType) => {
  let response = null;
  try {
    response = await axios({
      method: "put",
      url: `${API_URL}/alerts/${data.id}`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        appId: data.appId,
        text: data.text,
        checkType: data.checkType,
        channels: [
          {
            channelType: data.channels[0].channelType,
            params: data.channels[0].params,
          },
        ],
        isActive: data.isActive,
      },
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to update alerts",
    });

    console.error(error);
  }

  return response?.data;
};

export const deleteAlert = async (token: string, id: string) => {
  let response = null;
  try {
    response = await axios({
      method: "delete",
      url: `${API_URL}/alerts/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    notification.error({
      message: "Error",
      description: "Failed to remove alert",
    });

    console.error(error);
  }

  if (response?.data.success) {
    notification.success({
      message: "Success",
      description: "Alert removed successfully",
    });
  }
};
