import { Flex, Tag, Typography } from "antd";
import { useLogs } from "../../providers/LogProvider";
import { colors } from "./colors";
import dayjs from "dayjs";
import { timeFormatSec } from "../../config/const";

export const ActiveFilters = () => {
  const {
    fromDate,
    filters,
    setFilters,
    liveTail,
    logBoundaryTimes,
    logsMeta,
  } = useLogs();

  const removeFilter = (key: string) => {
    if (!filters) return;
    const activeFilters = { ...filters };
    // eslint-disable-next-line
    // @ts-ignore
    delete activeFilters[key];
    setFilters(activeFilters);
  };

  const removeTimeFilter = (key: string) => {
    if (!filters) return;
    const activeFilters = { ...filters };
    // eslint-disable-next-line
    // @ts-ignore
    delete activeFilters[key];
    const now = new Date();

    setFilters({
      ...activeFilters,
      dateFrom: now.getTime().toString(),
      dateTo: now.getTime().toString(),
    });
  };

  const notAllRecords = logsMeta && logsMeta.total > 500;

  console.log("filters", filters);
  if (!filters) return null;
  return (
    <Flex justify="flex-start" align="center">
      <div></div>
      {Object.entries(filters).map(([key, value]) => {
        if (
          key === "direction" ||
          key === "activeApp" ||
          key === "text" ||
          key === "worker"
        )
          return null;
        if (key === "relativeTime" && liveTail) {
          return null;
        }
        if (key === "dateFrom") {
          if (liveTail) {
            return (
              <Tag
                style={{ fontSize: "14px", padding: "4px 10px" }}
                color={colors[key]}
              >
                {`From: ${dayjs(new Date(liveTail)).format(timeFormatSec)}`}
              </Tag>
            );
          }

          if (filters.timestamp !== "yes" && logBoundaryTimes) {
            return (
              <Tag
                style={{ fontSize: "14px", padding: "4px 10px" }}
                color={colors[key]}
              >
                {`From: ${dayjs(
                  new Date(
                    notAllRecords || filters.dateTo === filters.dateFrom
                      ? logBoundaryTimes.from
                      : parseInt(filters.dateFrom),
                  ),
                ).format(timeFormatSec)}`}
              </Tag>
            );
          }
          return null;
        }
        if (key === "dateTo") {
          if (liveTail) {
            return null;
          }
          if (!filters.timestamp && logBoundaryTimes) {
            return (
              <Tag
                style={{ fontSize: "14px", padding: "4px 10px" }}
                color={colors[key]}
              >
                {`To: ${dayjs(
                  new Date(
                    notAllRecords || filters.dateTo === filters.dateFrom
                      ? logBoundaryTimes.to
                      : parseInt(filters.dateTo),
                  ),
                ).format(timeFormatSec)}`}
              </Tag>
            );
          }
          return null;
        }

        if (key === "timestamp") {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
              closable
              onClose={() => removeTimeFilter("timestamp")}
            >
              {`Timestamp: ${dayjs(new Date(parseInt(filters.dateFrom))).format(
                timeFormatSec,
              )}`}
            </Tag>
          );
        }

        if (key === "relativeTime") {
          return (
            <Tag
              style={{ fontSize: "14px", padding: "4px 10px" }}
              color={colors[key]}
              closable
              onClose={() => removeTimeFilter("relativeTime")}
            >
              {`Last 
              ${filters[key]?.split("#")[0]}${filters[key]?.split("#")[1]}`}
            </Tag>
          );
        }

        return (
          <Tag
            style={{ fontSize: "14px", padding: "4px 10px" }}
            color={colors[key]}
            closable
            onClose={() => {
              removeFilter(key);
            }}
          >
            {value}
          </Tag>
        );
      })}
    </Flex>
  );
};
